/**
 * Publicis
 * @author Sébastien D.
 * 20191218 
 */


/********  Browsers detection  *******/
/**
 * isChromeBrowser()
 * @returns {Boolean}
 * @description Test if the browser is Chrome
 */
function isChromeBrowser(){
	return !!window.chrome && !!window.chrome.webstore;
}

/**
 * isOperaBrowser()
 * @returns {Boolean}
 * @description Test if the browser is Opera
 */
function isOperaBrowser(){
	return (!!window.opr && !!opr.addons) || !!window.opera || navigator.userAgent.indexOf(' OPR/') >= 0;
}

/**
 * isSafariBrowser()
 * @returns {Boolean}
 * @description Test if the browser is Safari
 */
function isSafariBrowser(){
	return /constructor/i.test(window.HTMLElement) || (function (p) { return p.toString() === "[object SafariRemoteNotification]"; })(!window['safari'] || (typeof safari !== 'undefined' && safari.pushNotification));
}

/**
 * isFirefoxBrowser()
 * @returns {Boolean}
 * @description Test if the browser is Mozilla Firefox
 */
function isFirefoxBrowser(){
	return typeof InstallTrigger !== 'undefined';
}


/**
 * IsIEBrowser()
 * @returns {Boolean}
 * @description Test if the browser is Internet Explorer
 */
function IsIEBrowser(){
	return /*@cc_on!@*/false || !!document.documentMode;
}

/**
 * IsIE7Browser()
 * @returns {Boolean}
 * @description Test if the browser is Internet Explorer Version 7
 */
function IsIE7Browser(){
	return document.all && !document.querySelector;
}


/**
 * IsIE8Browser()
 * @returns {Boolean}
 * @description Test if the browser is Internet Explorer Version 8
 */
function IsIE8Browser(){
	return document.all && document.querySelector && !document.addEventListener;
}

/**
 * IsIE9Browser()
 * @returns {Boolean}
 * @description Test if the browser is Internet Explorer Version 9
 */
function IsIE9Browser(){
	var result = false;
	if(parseInt(navigator.userAgent.indexOf("MSIE 9.0")) >= 0)
	{
		result = true; 
	}
	return result;
}

/**
 * IsIE10Browser()
 * @returns {Boolean}
 * @description Test if the browser is Internet Explorer Version 10
 */
function IsIE10Browser(){
	var result = false;
	if ($.browser.msie && $.browser.version === 10){
        result = true;
	}
	return result;
}

/** 
 * @description Check if is IE11
 * @returns {boolean} true if is Internet Explorer 11
 */
function IsIE11Browser(){
    return !!window.MSInputMethodContext && !!document.documentMode;
}


/** 
 * @description Check if is Edge browser
 * @returns {boolean} true if is Internet Explorer 11
 */
function isEdgeBrowser(){
    return !IsIEBrowser() && !!window.StyleMedia;
}

/** 
 * @description Get Browser
 * @returns {string} name of browser
 */
function GetBrowser(){
    var _name = "";

    if(isChromeBrowser()){
        _name = "chrome";
    }
    else if(isSafariBrowser()){
        _name = "safari";
    }
    else if(isFirefoxBrowser()){
        _name = "firefox";
    }
    else if(isOperaBrowser()){
        _name = "opera";
    }
    else if(isEdgeBrowser()){
        _name = "edge";
    }
    else if(IsIEBrowser()){
        if(IsIE11Browser()){
            _name = "ie11";
        }
        else if(IsIE10Browser()){
            _name = "ie10";
        }
        else if(IsIE9Browser()){
            _name = "ie9";
        }
        else if(IsIE8Browser()){
            _name = "ie8";
        }
        else if(IsIE7Browser()){
            _name = "ie7";
        }
        else{
            _name = "ie_unknow";
        }
    }
    else{
        _name = "unknow";
    }
    return _name;
}


/**
 * IsUnderIpad()
 * @returns {Boolean}
 * @description Test if the device is under iPad or not.
 */
function IsUnderIpad(){
	return navigator.userAgent.indexOf( "iPad" ) !== -1;
}


/**
 * IsUnderIphone()
 * @returns {Boolean}
 * @description Test if the device is under iPhone or not.
 */
function IsUnderIphone(){
	return navigator.userAgent.indexOf( "iPhone" ) !== -1;
}


/**
 * IsUnderIOS()
 * @returns {Boolean}
 * @description Test if the device is under IOS or not
 */
function IsUnderIOS(){
    //return /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
    var iDevices = [
        'iPad Simulator',
        'iPhone Simulator',
        'iPod Simulator',
        'iPad',
        'iPhone',
        'iPod'
      ];
    
      if (!!navigator.platform) {
        while (iDevices.length) {
          if (navigator.platform === iDevices.pop()){ return true; }
        }
      }
    
      return false;
}

/**
 * IsUnderAndroid()
 * @returns {Boolean}
 * @description Test if the device is under Android or not
 */
function IsUnderAndroid(){
	var ua = navigator.userAgent.toLowerCase();
	return ua.indexOf("android") > -1;
}

/** 
 * @description Check if on mobile device 
 * @returns {boolean} true if mobile device
 */
function isMobileDevice() {
    return (typeof window.orientation !== "undefined") || (navigator.userAgent.indexOf('IEMobile') !== -1);
};

/** 
 * @description Check if on mobile width 
 * @returns {boolean} true if mobile width
 */
function isMobile() {
    return window.innerWidth < 992;
};


/**
 * @description Check if we are on Landscape or not
 * @returns {Boolean}
 * Test if the device is on Landscape or not
 */
function isLandscape(){
	var result = false;
	/*var orientation =  window.orientation;
	if ((orientation === undefined && document.documentElement.clientWidth > document.documentElement.clientHeight) || (orientation !== undefined && (orientation === -90 || orientation === 90))) {
        result = true;
    }*/
	return window.innerHeight <= window.innerWidth;
}

/**
 * @description Return value of get parameter
 * @param {string} name of the get parameter 
 * @returns {string} results
 */
function getParameterByName(name){
    name = name.replace(/[\[]/, "\\\[").replace(/[\]]/, "\\\]");
    var regex = new RegExp("[\\?&]" + name + "=([^&#]*)"),
        results = regex.exec(location.search);
    return results === null ? "" : decodeURIComponent(results[1].replace(/\+/g, " "));
}

/* COOKIES */
/**
 * @description Create a javascript cookie
 * @param {string} pName Name of the cookie
 * @param {string} pValue Value of the cookie
 * @param {int} pDays Validity in days
 */
function createCookie(pName, pValue, pSeconds) {
    var _expires = "";

    if (pSeconds) {
        var _date = new Date();
        _date.setTime(_date.getTime() + (pSeconds * 1000));
        _expires = "; expires=" + _date.toGMTString();
    }
    document.cookie = pName + "=" + pValue + _expires + "; path=/";
}

/**
 * @description Read a javascript cookie
 * @param {string} pName Name of the cookie
 * @returns {string|null} if cookie exist or not
 */
function readCookie(pName) {
    var _nameEQ = pName + "=";
    var _ca = document.cookie.split(';');
    if (_ca !== undefined && _ca !== null && _ca !== '') {
        for (var i = 0; i < _ca.length; i++) {
            var _c = _ca[i];
            while (_c.charAt(0) === ' ') _c = _c.substring(1, _c.length);
            if (_c.indexOf(_nameEQ) === 0) return _c.substring(_nameEQ.length, _c.length);
        }
    }
    return null;
}

/**
 * @description Erase a javascript cookie
 * @param {string} pName Name of the cookie
 */
function eraseCookie(pName) {
    createCookie(pName, "", -1);
}

/**
 * @description Encode string to base64
 * @param {string} str parameter to encode 
 * @returns {string} _result base64 string
 */
function base64_encode( str ) {
    var _result = false;
    if(undefined !== str && str !== null && str !== "" && str !== "[]"){
        _result = window.btoa(unescape(encodeURIComponent( str )));
    }
    return _result;
}
  
  /**
 * Decode base64 to string
 * @param {string} str parameter to decode
 * @returns {string} _result string decoded
 */
function base64_decode( str ) {
    var _result = false;
    if(undefined !== str && str !== null && str !== "" && str !== "[]"){
        _result = decodeURIComponent(escape(window.atob( str )));
    }
    return _result;
}

/*
* Replace all SVG images with inline SVG
*/
function replaceSVG(pElm){
    $(pElm + ' img').each(function () {
        var $img = jQuery(this);
        var imgID = $img.attr('id');
        var imgClass = $img.attr('class');
        var imgURL = $img.attr('src');

        $.get(imgURL, function (data) {
            // Get the SVG tag, ignore the rest
            var $svg = jQuery(data).find('svg');

            // Add replaced image's ID to the new SVG
            if (typeof imgID !== 'undefined') {
                $svg = $svg.attr('id', imgID);
            }
            // Add replaced image's classes to the new SVG
            if (typeof imgClass !== 'undefined') {
                $svg = $svg.attr('class', imgClass + ' replaced-svg');
            }

            // Remove any invalid XML tags as per http://validator.w3.org
            $svg = $svg.removeAttr('xmlns:a');

            // Check if the viewport is set, if the viewport is not set the SVG wont't scale.
            if (!$svg.attr('viewBox') && $svg.attr('height') && $svg.attr('width')) {
                $svg.attr('viewBox', '0 0 ' + $svg.attr('height') + ' ' + $svg.attr('width'));
            }

            // Replace image with new SVG
            $img.replaceWith($svg);
        }, 'xml');
    });
}

function EmailValidation(pEmail){
    var _reg = new RegExp('^[a-z0-9]+([_|\.|-]{1}[a-z0-9]+)*@[a-z0-9]+([_|\.|-]{1}[a-z0-9]+)*[\.]{1}[a-z]{2,6}$', 'i');
    var _result = {};
    _result.error = [];
    _result.valid = true;

    if(undefined === gEmailRejectList){
        var gEmailRejectList = [];
    }

    if(undefined === pEmail || pEmail === "" || pEmail === null){
        //Email empty
        _result.error.push("empty");

        if(_result.valid){
            _result.valid = false;
        }
    }
    else{
        //Bad formation
        if(!_reg.test(pEmail)){
            _result.error.push("form");
    
            if(_result.valid){
                _result.valid = false;
            }
        }
        else{
            //Domain Error
            if(gRejectList.indexOf(pEmail.split('@')[1]) >= 0){
                _result.error.push("domain");

                if(_result.valid){
                    _result.valid = false;
                }
            }

        }
    }
    return _result;
}

function PasswordValidation(pPwd){
    /**
        ^                   The password string will start this way
        (?=.*[a-z])         The string must contain at least 1 lowercase alphabetical character
        (?=.*[A-Z])         The string must contain at least 1 uppercase alphabetical character
        (?=.*[0-9])         The string must contain at least 1 numeric character
        (?=.[!@#\$%\^&])    The string must contain at least one special character, but we are escaping reserved RegEx characters to avoid conflict
        (?=.{8,})          	The string must be eight characters or longer
    */
    var _reg = new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#_\$%\^&\*\\-])(?=.{8,})');
    var _result = {};
    _result.valid = true;
    _result.error = [];

    if (!_reg.test(pPwd)) {
        _result.valid = false;
        var _lowercase = new RegExp('^(?=.*[a-z])');
        var _uppercase = new RegExp('^(?=.*[A-Z])');
        var _numeric = new RegExp('^(?=.*[0-9])');
        var _special = new RegExp('^(?=.[!@#\$%\^&])');
        var _width = new RegExp('(?=.{8,})');

        if(!_lowercase.test(pPwd)){
            _result.error.push("lowercase");
        }

        if(!_uppercase.test(pPwd)){
            _result.error.push("uppercase");
        }

        if(!_numeric.test(pPwd)){
            _result.error.push("numeric");
        }

        if(!_special.test(pPwd)){
            _result.error.push("special");
        }

        if(!_width.test(pPwd)){
            _result.error.push("width");
        }
    }
    return _result;
}

function createSpriteSheetAnimation(containerCSSSelector, spritesheetPath, frameWidth, frameHeight, framerate, shouldLoop){
    var image = new Image();
    image.addEventListener('load', function(){
      var renderer = new SpriteAnim.CanvasRenderer(document.querySelector(containerCSSSelector), image);
      var parser = new SpriteAnim.SimpleParser(image, {width: frameWidth, height: frameHeight});
      var animation = new SpriteAnim(parser, renderer, {frameRate: framerate, loop: shouldLoop});
      animation.play();
    });
    image.src = spritesheetPath;
}

//Youtube 
function playVideo(pID){
    var tag = document.createElement('script');
    var firstScriptTag = document.getElementsByTagName('script')[0];

    document.getElementById(pID).innerHTML = "<div id='player'></div>";
    tag.src = "https://www.youtube.com/iframe_api";
    firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
}

function onYouTubeIframeAPIReady() {
    gPlayer = new YT.Player('player', {
        height: gVideoHeight,
        width: gVideoWidth,
        videoId: gVideoID,
        events: {
            'onReady': onPlayerReady,
            'onStateChange': onPlayerStateChange,
            'onError': onPlayerError
        }
    });
}

function onPlayerReady(event) {
    gPlayer.setSize(gVideoWidth, gVideoHeight);
    gPlayer.loadVideoById(gVideoID);
    event.target.playVideo();
}

function onPlayerStateChange(event) {
    if (event.data == YT.PlayerState.ENDED ) {
        console.log("Video ended");
        afterVideo();
    }
}

function stopVideo() {
    gPlayer.stopVideo();
}

function onPlayerError(e){
    console.log('error');
    stopVideo();
    showBtnNoVideo();
}

//AnimateCSS
function animateCSS(element, animationName, callback) {
    var node = document.querySelector(element);
    node.classList.add('animated', animationName);

    function handleAnimationEnd() {
        node.classList.remove('animated', animationName);
        node.removeEventListener('animationend', handleAnimationEnd);

        if (typeof callback === 'function') callback();
    }

    node.addEventListener('animationend', handleAnimationEnd);
}

function scrollTop(pXCoord = 0, pYCoord = 0){
    window.scrollTo(pXCoord, pYCoord);
}


/*
    Mylan
*/
function slideScreen(pID){
    var node = document.querySelectorAll("#mylanApp .article");
    for(var i = 0; i < node.length; i++){
        var _elm = node[i];
        //console.log(_elm.id);
        if(pID === _elm.id){
            if(_elm.classList.contains("hide")){
                _elm.classList.remove("hide");

                if(pID === "questionResult"){
                    var _result = document.querySelectorAll("#questionResult .state")[0];

                    _result.classList.remove("animated");
                    _result.classList.remove("jackInTheBox");
                    _result.classList.add("hidden");

                    setTimeout(function(){
                        _result.classList.remove("hidden");
                        _result.classList.add("animated");
                        _result.classList.add("jackInTheBox");
                    }, 300);
                }
            }

        }
        else{
            if(!_elm.classList.contains("hide")){
                _elm.classList.add("hide");
            }
        }
    }
}
