/**
 * Publicis
 * @author Sébastien D.
 * 20190614
 */
var gPlayer = null;
var gVideoID = ""; 
var gVideoHeight = 1080;
var gVideoWidth = 1920;
var gIsMobile = true;
var gLocalPath = "";
var gIsLandscape = false;
var gCookieValidity = site_config.COOKIE_VALIDITY;
var gWSDomainURL = site_config.API_ROOT_URL;
var gAPIVersion = site_config.API_VERSION;
var gRequestTimeout = site_config.REQUEST_TIMEOUT;
