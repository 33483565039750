//Global events

//Page is ready
(function() {
    // your page initialization code here
    // the DOM will be available here
    //console.log("Ready");
})();

///Resize event
window.onresize = function(){
    //console.log("Resize window - Width: " + window.innerWidth + " Height: " + window.innerHeight);
};

// called when the window is scrolled.
window.onscroll = function (e) {  
    //console.log("Scroll position: " + window.scrollY);
};

//Orientation change Event
window.onorientationchange = function() { 
    //console.log("the orientation of the device is now " + screen.orientation.angle);
    //console.log("Orientation : " + window.orientation);
};

//Device orientation
window.addEventListener("deviceorientation", function(e){
    var absolute = e.absolute;
    var alpha    = e.alpha; //Z axis (0 - 360°)
    var beta     = e.beta; //Y axis (-180° - 180°) 
    var gamma    = e.gamma; // X axis (-90° - 90°)

    //console.log("Absolute : " + absolute + " - Alpha: " + alpha + " - Beta: " + beta + " - Gamma: " + gamma);
}, true);