var store = {
    debug: true,
    state: -1,
    presentationID : "",
    token: "",
    pseudo: "",
    version: gAPIVersion,
    ajaxURI : gWSDomainURL,
};

let CodeForm = Vue.component('CodeForm', { 
    props : {
        code : String,
        error: Boolean,
        submitted: Boolean
    },
    template: `<div class="code-form-component">
        <h3 class="code-form-title">Rejoignez le quiz en tapant le code</h3>
        <div>
            <input class="code-form-input" type="text" placeholder="Tapez le code ici" v-model="code" 
                v-on:keyup.enter="sendCode"/>
        </div>
        <div class="code-form-error"><div class="alert alert-danger" v-if="error">Le code est incorrect.</div></div>
        <div class="code-form-validate" v-if="code && code !== '' && !submitted">
            <button class="button valider" v-on:click="sendCode">
                <span class="valider-illustration"></span>
                <span class="valider-text">Valider<span>
            </button>
        </div>
    </div>`,
    methods : {
        sendCode : function(event){
            this.$emit("click", this.code);
            //console.log("Child : " + this.code);
            if(event.key && event.key.toLowerCase() === "enter"){
                $(event.target).blur();
            }
        }
    },
    data: function(){
        return{
            code : ""
        };
    }
});

let MylanApp = new Vue({
    el: '#mylanApp',
    component: { 
        "code-form" : CodeForm
    },
    data : {
        state : -1,
        ready : false,
        interval : null,
        question: {
            id: -1,
            title : "",
            questionsList: [],
            response: -1
        },
        responseTime : false,
        responseCorrect : false,
        responseSelected: null,
        errorCode : false,
        codeSubmitted : false,
        store : store,
        firstQuestion : true,
    },
    created: function(){
        //console.log("Ok");
        setTimeout(this.loading, 1000);
    },
    watch : {
        state: function (newVal, val){
            //console.log("state change");
            switch(newVal){
                //Initial state
                case -1:
                    window.clearInterval(MylanApp.interval);
                    MylanApp.resetPresentation();
                    break;

                //Wait question
                case 0:
                    window.clearInterval(MylanApp.interval);
                    if(MylanApp.responseSelected !== null){
                        //MylanApp.responseTime = false;
                        slideScreen("questionResult");

                        MylanApp.interval = setInterval(function(){
                            MylanApp.getState();
                        }, 2000);

                        /*setTimeout(function(){
                            slideScreen("waitQuestion");

                            setTimeout(function(){
                                MylanApp.responseSelected = null;
                            }, 300);

                            
                        }, 5000);*/
                    }
                    else{
                        slideScreen("waitQuestion");
                        MylanApp.interval = setInterval(function(){
                            MylanApp.getState();
                        }, 2000);
                    }
                    break;
                    
                //Question
                case 1:
                    window.clearInterval(MylanApp.interval);
                    /*MylanApp.responseSelected = null;
                    slideScreen("newQuestion");

                    MylanApp.interval = setInterval(function(){
                        MylanApp.getState();
                    }, 2000);
                    MylanApp.responseTime = true;*/
                    
                    var _question = readCookie("MylanTwicor_question");
                    var _response = readCookie("MylanTwicor_response");

                    if(_question !== null && parseInt(_question) === parseInt(this.question.id) && _response !== null){
                        MylanApp.responseSelected = parseInt(_response);
                        slideScreen('waitVote');
                    }
                    else{
                        MylanApp.responseSelected = null;
                        slideScreen("newQuestion");
                    }

                    if(this.firstQuestion){
                        this.firstQuestion = false;
                    }

                    MylanApp.interval = setInterval(function(){
                        MylanApp.getState();
                    }, 2000);
                    MylanApp.responseTime = true;

                    break;
                    
                //End of presentation
                /*case 2:
                        window.clearInterval(MylanApp.interval);
                        slideScreen("presentationEnded");
                    break;*/

                default:
                        window.clearInterval(MylanApp.interval);
                    break;
            }
        },
        store: function (newVal, val){
            //console.log("store change");
        },
    },
    methods : {
        /**
         * @description Se connecter à une présentation
         * @param {String} value ID de la présentation
         */
        getSessionCode : function(value){

            //Uppercase
            value = value.toLocaleLowerCase();
            //console.log("Parent : " + value);
            MylanApp.errorCode = false;
            //gWSDomainURL
            axios({
                method: 'get',
                //url: "datas/success/get/presentation.json",
                url: gWSDomainURL + "presentation/" + value,
                data: Qs.stringify({}),
                headers : {
                    "Accept-Version" : store.version,
                    "Content-Type" : "application/json",
                    "Access-Control-Allow-Origin" : "*",
                    "Access-Control-Allow-Credentials" : false,
                    "Access-Control-Allow-Headers" : "Origin, Content-Type, Accept, Authorization, X-Request-With",
                    "Access-Control-Allow-Methods" : "GET, POST, DELETE",
                    'Accept': 'application/json'
                },
                timeout: gRequestTimeout,
            })
            .then(function (response) {
                if(response.data){
                    //console.log(response.data);
                    var _response = response.data;
                    createCookie("MylanTwicor_token", _response.token, gCookieValidity);
                    store.token = _response.token;
                    createCookie("MylanTwicor_presentation", value, gCookieValidity);
                    store.presentationID = value;
                    createCookie("MylanTwicor_pseudo", _response.pseudo, gCookieValidity);
                    store.pseudo = _response.pseudo;
                    slideScreen("waitQuestion");
                    MylanApp.state = 0;
                    store.state = 0;
                    //this.$forceUpdate();
                }
            })
            .catch(function (error) {
                //var _response = error.response;
                //console.log(error, _response);
                MylanApp.errorCode = true;

                var _response = error.response;
                if(_response && _response.status !== undefined){
                    if (!navigator.onLine || _response.status === 408) {
                        //Offline
                        alert("Vous avez été déconnecté. Rééssayez une fois connecté");
                    } else {
                        //Online
                        MylanApp.errorCode = true;
                    }
                }
            });
        },
        /**
         * @description Etat de l'application
         * @param null
         */
        getState : function(){
            axios({
                method: 'get',
                headers : {
                    "token" : store.token,
                    "Accept-Version" : store.version
                },
                //url: "datas/success/get/question.json",
                url: gWSDomainURL + "presentation/" + store.presentationID + "/state",
                data: Qs.stringify({}),
                timeout: gRequestTimeout,
            })
            .then(function (response) {
                if(response.data){
                    //console.log(response.data);
                    var _response = response.data;
                    /*createCookie("MylanTwicor_token", _response.token, gCookieValidity);
                    store.token = _response.token;
                    createCookie("MylanTwicor_presentation", value, gCookieValidity);
                    store.presentationID = value;
                    slideScreen("waitQuestion");
                    MylanApp.state = 0;
                    store.state = 0;*/
                    MylanApp.state = _response.state;
                    store.state = _response.state;
                    
                    if(_response.form && _response.form.question !== ""){
                        MylanApp.question = {
                            id : _response.form.id_question,
                            title : _response.form.question,
                            questionsList: _response.form.propositions,
                            response: _response.form.id_response
                        };
                        console.log("Question : ", MylanApp.question);
                    }
                }
            })
            .catch(function (error) {
                //Error
                var _response = error.response;
                
                if(_response && _response.status !== undefined){
                    switch(_response.status){
                        case 403:
                            console.log("Token invalide");
                            if(_token === null || _presentationID === null || _pseudo === null){
                                MylanApp.resetPresentation();
                            }
                            else{
                                slideScreen("presentationEnded");
                            }
                        break;
    
                        case 404:
                            console.log("Présentation terminée ou invalide");
                            var _token = readCookie("MylanTwicor_token");
                            var _presentationID = readCookie("MylanTwicor_presentation");
                            var _pseudo = readCookie("MylanTwicor_pseudo");
                            window.clearInterval(MylanApp.interval);
                            
                            if(_token === null || _presentationID === null || _pseudo === null){
                                MylanApp.resetPresentation();
                            }
                            else{
                                slideScreen("presentationEnded");
                            }
                        break;
            
                        default:
                            if (navigator.onLine) {
                                //Online
                                console.log("Error default - Status : " + _response.status, _response);
                                if(_token === null || _presentationID === null || _pseudo === null){
                                    MylanApp.resetPresentation();
                                }
                                else{
                                    slideScreen("presentationEnded");
                                }
                            } else {
                                //Offline
                            }
                        break;
                    }
                }
            });
        },
        /**
         * @description Réponse à une question
         * @param null
         */
        postResponse : function(){
            //console.log("Parent : " + value);
            MylanApp.errorCode = false;
            var _response = {
                "id_response" : MylanApp.responseSelected
            };
            //gWSDomainURL
            axios({
                method: 'post',
                //url: "datas/success/get/presentation.json",
                url: gWSDomainURL + "presentation/" + store.presentationID + "/" + MylanApp.question.id,
                data: JSON.stringify(_response),
                headers : {
                    "token" : store.token,
                    "Accept-Version" : store.version,
                    "Content-Type" : "application/json",
                },
                timeout: gRequestTimeout,
            })
            .then(function (response) {
                console.log("Vote envoyé : " + MylanApp.question.id);

                eraseCookie("MylanTwicor_question");
                eraseCookie("MylanTwicor_response");
                createCookie("MylanTwicor_question", MylanApp.question.id, gCookieValidity);
                createCookie("MylanTwicor_response", MylanApp.responseSelected, gCookieValidity);

                slideScreen('waitVote');
            })
            .catch(function (error) {
                //var _response = error.response;
                console.log(error, _response);
                var _response = error.response;
                if (!navigator.onLine || _response.status === 408) {
                    //Offline
                    alert("Vous avez été déconnecté lors de l'envoi de la réponse. Rééssayez une fois reconnecté");
                } else {
                    //Online
                }
                //MylanApp.resetPresentation();
            });
        },
        /**
         * @description Se déconnecter à une présentation
         * @param null
         */
        disconnectPresentation : function(){
            //console.log("Parent : " + value);
            MylanApp.errorCode = false;
            //gWSDomainURL
            axios({
                method: 'delete',
                url: gWSDomainURL + "participant",
                data: Qs.stringify({}),
                headers : {
                    "token" : store.token,
                    "Accept-Version" : store.version
                },
                timeout: gRequestTimeout,
            })
            .then(function (response) {
                console.log("Déconnecté de la présentation");
                MylanApp.resetPresentation();
            })
            .catch(function (error) {
                //var _response = error.response;
                console.log(error, _response);
                MylanApp.resetPresentation();
            });
        },
        selectQuestion : function(pID){
            //console.log(pID);
            this.responseSelected = pID;

            if(pID == this.question.response){
                //console.log("Bonne réponse");
                //this.responseCorrect = true;
            }
        },
        loading: function(){
            var _token = readCookie("MylanTwicor_token");
            var _presentationID = readCookie("MylanTwicor_presentation");
            var _pseudo = readCookie("MylanTwicor_pseudo");
            
            if(_token === null || _presentationID === null || _pseudo === null){
                this.ready = true;
                this.state = -1;
                store.state = -1;

                slideScreen("quizzForm");

                setTimeout(function(){
                    document.getElementById("initBox").classList.add("hidden");
                }, 300);
            }
            else{
                this.ready = true;
                this.state = 0;
                store.state = 0;
                store.token = _token;
                store.presentationID = _presentationID;
                store.pseudo = _pseudo;
            }
        },
        resetPresentation: function(){
            $("#modalEndPresentation").modal("hide");

            this.state = -1;
            store.state = -1;
            store.token = "";
            store.presentationID = "";
            store.pseudo = "";
            this.responseSelected = null;

            slideScreen("quizzForm");
            eraseCookie("MylanTwicor_token");
            eraseCookie("MylanTwicor_presentation");
            eraseCookie("MylanTwicor_pseudo");
            eraseCookie("MylanTwicor_question");
            eraseCookie("MylanTwicor_response");
            window.clearInterval(this.interval);

            this.question = {
                title : "",
                questionsList: [],
                response: -1,
                id: -1
            };

            setTimeout(function(){
                $(".code-form-input").first().focus();
            }, 500);
        },
    },
    computed: {
        
    }
});
